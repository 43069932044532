import React, { useEffect, useRef, useState } from "react";
import { useController } from "react-hook-form";
import mediaAPI from "../../../../apis/api/media";
import { getS3BEMediaUrl } from "../../../../helper/media";
import { toast } from "react-toastify";
import { cn } from "../../../../helper/utils";
import Loading from "../../configuration/components/loading";

const UploadField = ({
  description,
  controller,
  multiple = false,
  callbackOnchange,
  accept,
  imageClassName,
  errorClassName,
  wrapperClassName,
  wrapperFormClassName,
}) => {
  const { field, fieldState } = useController(controller);

  const { error } = fieldState;

  const [dragActive, setDragActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const wrapperRef = useRef(null);

  const handleFile = async (fileData) => {
    if (fileData.length < 1 || loading) return;

    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("file", fileData[0]);

      const uploaded = await mediaAPI.uploadMedia(formData);
      // const formData = new FormData();
      // for (let i = 0; i < fileData.length; i++) {
      //   formData.append(`file`, fileData[i]);
      //   formData.append(`name`, fileData[i].name);
      //   formData.append(`type`, "image");
      // }

      callbackOnchange && callbackOnchange(fileData);

      // const uploaded = await mediaAPI.uploadMedia(formData);

      if (uploaded) {
        field.onChange({
          id: uploaded?.data?.id,
          path: uploaded?.data?.path,
        });
      }

      setLoading(false);
    } catch (error) {
      inputRef.current.value = null;
      setLoading(false);
      toast.error("Upload media failed!");
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  useEffect(() => {
    if (error && wrapperRef.current) {
      wrapperRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [error]);

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e?.dataTransfer?.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e?.target?.files && e?.target?.files[0]) {
      const selectedFiles = Array.from(e.target.files);
      handleFile(selectedFiles);
    }
  };

  const handleClear = function (e) {
    inputRef.current.value = null;
    e.stopPropagation();
    field.onChange(null);
    callbackOnchange && callbackOnchange(null);
  };

  const onButtonClick = () => {
    inputRef?.current?.click();
  };

  return (
    <form
      className={cn("wrap-drag-drop-file flex-1", wrapperFormClassName)}
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <div
        ref={wrapperRef}
        onClick={onButtonClick}
        className={cn(
          "relative flex items-center rounded-[8px] justify-center border-dashed border-[1px] border-black w-full",
          wrapperClassName,
          {
            "min-h-[140px]": !field.value || loading,
            "!border-red-600": error,
          }
        )}
      >
        <div className="w-full text-center">
          <div className="text-sm">
            {loading && (
              <div className="flex justify-center items-center">
                <Loading width="w-6" height="h-6" />
              </div>
            )}
            {!loading && field.value ? (
              <img
                src={getS3BEMediaUrl(field.value?.path)}
                className={`${imageClassName} w-full h-[140px] rounded-lg `}
                alt={`media`}
              />
            ) : (
              !loading && description
            )}
          </div>
        </div>
        <div className="description absolute top-2.5 left-2.5 flex justify-start items-center">
          <input
            ref={inputRef}
            accept={accept || "image/*"}
            type="file"
            className="hidden"
            multiple={multiple || false}
            onChange={handleChange}
          />
        </div>
        {field.value && (
          <button
            onClick={handleClear}
            type="button"
            className="absolute top-[10px] right-[10px] inline-flex items-center border-[1px_solid_#0000005E] bg-transparent text-black !border-none outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </button>
        )}
        {dragActive && (
          <div
            className="absolute w-full h-full top-0 left-0"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </div>
      {error && (
        <div className={`${errorClassName}`}>
          <p className={error ? "text-red-500 text-sm" : ""}>{error.message}</p>
        </div>
      )}
    </form>
  );
};

export default UploadField;
