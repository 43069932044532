/** @format */

import {
  GET_LIST_CUSTOMERS,
  GET_LIST_UNITS,
  SET_EDITABLE_CUSTOMER,
  SET_EDITABLE_CUSTOMER_NOTE,
  SET_EDITABLE_PROPERTY_COLUMN,
  ADD_ONE_CUSTOMER,
  GET_LIST_MEDIA,
  SET_EDITABLE_MEDIA,
  GET_LIST_GALLERIES,
  SET_EDITABLE_GALLERY,
  SET_IS_SHOW_DELETE_GALLERY_MODAL,
  GET_LIST_APPOINTMENT,
  GET_LIST_AMENITY,
  EDIT_ONE_CUSTOMER,
  REMOVE_ONE_CUSTOMER,
  GET_LIST_AGENTS,
  GET_LIST_FAQS,
  SET_CMS_MEDIA_CONTENT,
  GET_LIST_PLAYLISTS,
  GET_LOADING_UNITS,
  GET_FOLDERS,
  SET_LIST_MEDIA_STATE,
  GET_DETAIL_PLAYLIST,
  GET_CONFIG,
  GET_THEME,
} from "./type";

const initialState = {
  customers: [],
  units: [],
  loadingUnits: false,
  editableCustomer: null,
  editablePropertyColumn: null,
  editableCustomerNote: null,
  media: [],
  mediaState: [],
  editableMedia: false,
  galleries: [],
  editableGallery: false,
  isShowDeleteGalleryModal: false,
  appointments: [],
  amenities: [],
  playlist: [],
  mediaType: "",
  playlistDetail: null,
  config: null,
  theme: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_LIST_APPOINTMENT:
      return {
        ...state,
        appointments: action?.data?.data,
      };
    case SET_IS_SHOW_DELETE_GALLERY_MODAL:
      return {
        ...state,
        isShowDeleteGalleryModal: action?.data,
      };
    case GET_LIST_FAQS:
      return {
        ...state,
        faqs: action?.data?.data,
      };
    case SET_EDITABLE_GALLERY:
      return {
        ...state,
        editableGallery: action?.data?.data,
      };
    case GET_LIST_GALLERIES:
      return {
        ...state,
        galleries: action?.data?.data,
      };
    case SET_EDITABLE_MEDIA:
      return {
        ...state,
        editableMedia: action?.data?.data,
      };
    case GET_LIST_MEDIA:
      return {
        ...state,
        media: action.data.data,
        total: action.data?.total || 0,
      };
    case GET_LIST_CUSTOMERS:
      return {
        ...state,
        customers: action.data.data,
      };
    case GET_LIST_AGENTS:
      return {
        ...state,
        agents: action.data.data,
      };
    case GET_LIST_UNITS:
      return {
        ...state,
        units: action.data.data,
      };
    case GET_LOADING_UNITS:
      return {
        ...state,
        loadingUnits: action.data,
      };
    case SET_EDITABLE_CUSTOMER:
      return {
        ...state,
        editableCustomer: action.data,
      };
    case SET_EDITABLE_CUSTOMER_NOTE:
      return {
        ...state,
        editableCustomerNote: action.data,
      };
    case SET_EDITABLE_PROPERTY_COLUMN:
      return {
        ...state,
        editablePropertyColumn: action.data,
      };
    case ADD_ONE_CUSTOMER:
      return {
        ...state,
        customers: [...state.customers, action.data],
      };
    case GET_LIST_AMENITY:
      return {
        ...state,
        amenities: action.data.data,
      };
    case EDIT_ONE_CUSTOMER:
      const tempCustomers = [...state.customers];
      const updatedCustomers = tempCustomers.map((customer) => {
        if (customer.id === action.id) {
          return { ...action.data };
        } else {
          return customer;
        }
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    case REMOVE_ONE_CUSTOMER:
      const tempCustomers1 = [...state.customers];
      const newCustomers = tempCustomers1.filter(
        (customer) => customer.id !== action.id
      );

      return {
        ...state,
        customers: [...newCustomers],
      };
    case SET_CMS_MEDIA_CONTENT:
      return {
        ...state,
        mediaType: action?.data,
      };
    case GET_LIST_PLAYLISTS:
      return {
        ...state,
        playlist: action?.data,
      };
    case GET_FOLDERS:
      return {
        ...state,
        folders: action?.data,
      };
    case SET_LIST_MEDIA_STATE:
      return {
        ...state,
        mediaState: action?.data,
      };
    case GET_DETAIL_PLAYLIST:
      return {
        ...state,
        playlistDetail: action?.data?.data,
      };
    case GET_CONFIG:
      return {
        ...state,
        config: action?.data?.data,
      };
    case GET_THEME:
      return {
        ...state,
        theme: action?.data?.data,
      };
    default:
      return state;
  }
};
