import List from "./list";
import ListModels from "./list-models";
import NewCreatingForm from "./new-creating-form";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add3DModelModal } from "./modals/add-model-modal";
import { Button, Modal } from "../../../components/commons";
import { fetch3DSettings } from "../../../../../reduxs/scene/action";
import { reqGetModelsMediaList } from "../../../../../reduxs/media/action";
import { getListInfoInThreeDCanvas } from "../../../../../reduxs/page-configuration/action";

const ContentSideCanvas = ({ pageState }) => {
  const dispatch = useDispatch();
  const models = useSelector((state) => state.media.models);
  const [isAddingModel, setIsAddingModel] = useState(false);

  useEffect(() => {
    pageState?.name === "canvas" &&
      (() => {
        dispatch(getListInfoInThreeDCanvas());
        dispatch(reqGetModelsMediaList());
        dispatch(fetch3DSettings());
      })();
  }, [pageState]);

  return (
    <div className=" h-[calc(100vh-132px-80px)]  overflow-y-scroll custom-scrollbar-10">
      <div className="mx-auto max-w-[1000px] 2xl:max-w-[1500px] my-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">3D Model Assets</h2>
          <Button onClick={() => setIsAddingModel(true)}>Add file</Button>
        </div>
        <ListModels data={models} />
      </div>
      <div className="mx-auto max-w-[1000px] 2xl:max-w-[1500px] my-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Canvas Configurator</h2>
          <Modal
            maskClosable
            maskClassName="bg-opacity-50"
            trigger={<Button>GENERATE DATA</Button>}
            content={(props) => <NewCreatingForm {...props} />}
          />
        </div>
        <List />
      </div>
      {isAddingModel && (
        <Add3DModelModal
          mediaType={'3d_model'}
          show={isAddingModel}
          setShow={setIsAddingModel}
          onCreateSuccess={() => { dispatch(reqGetModelsMediaList()) }}
        />
      )}
    </div>
  );
};

export default ContentSideCanvas;
