import ApiClient from '../apiClient';
import { authFetcher, defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const getConfig = () =>
    defaultClient.get(endPoint.GET_CONFIG);

const updateConfig = (data) =>
    defaultClient.put(endPoint.UPDATE_CONFIG, data);

export default {
    getConfig,
    updateConfig
};
