import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import InputField from "../../../components/commons/inputField";
import { Button } from "../../../components/commons";
import { COMMON_MESSAGE, REGEX } from "../../utils";
import { reqCreateNewCanvas } from "../../../../../reduxs/page-configuration/action";
import _ from "lodash";
import { toast } from "react-toastify";

const NewCreatingForm = ({ isShow, setIsShow }) => {
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    units: yup
      .string()
      .required("Units is required")
      .matches(REGEX.NUMBER_INT, COMMON_MESSAGE.NUMBER_INT),
    levels: yup
      .string()
      .required("Levels is required")
      .matches(REGEX.NUMBER_INT, COMMON_MESSAGE.NUMBER_INT)
      .test(
        "lessThanOrEqualToUnits",
        "Levels must be less than or equal to Units",
        function (value) {
          return +value <= this.parent.units;
        }
      ),
    amenities: yup
      .string()
      .required("Amenities is required")
      .matches(REGEX.NUMBER_INT, COMMON_MESSAGE.NUMBER_INT),
    transportOptions: yup
      .string()
      .required("Transport options is required")
      .matches(REGEX.NUMBER_INT, COMMON_MESSAGE.NUMBER_INT),
  });

  const { control, formState, handleSubmit } = useForm({
    defaultValues: {
      type: "BHT",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (v) => {
    const data = _.mapValues(_.omit(v, "type"), _.toNumber);

    await dispatch(
      reqCreateNewCanvas({
        data,
        onSuccess: () => {
          setIsShow(false);
          toast.success("Create canvas successfully");
        },
        onError: () => toast.error("Create canvas failed"),
      })
    );
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <h2 className="mb-3 text-xl">3D Canvas Schema</h2>
      <div className="mb-3">
        <InputField
          disabled
          isRequired
          controller={{
            name: `type`,
            control,
          }}
          inputProps={{ placeholder: "Type" }}
        />
      </div>
      <div className="mb-3">
        <InputField
          isRequired
          label="Units"
          controller={{
            name: `units`,
            control,
          }}
          inputProps={{
            placeholder: "How many total units ?",
            type: "number",
            isInt: true,
          }}
        />
      </div>
      <div className="mb-3">
        <InputField
          isRequired
          label="Levels"
          controller={{
            name: `levels`,
            control,
          }}
          inputProps={{
            placeholder: "How many levels ?",
            type: "number",
            isInt: true,
          }}
        />
      </div>
      <div className="mb-3">
        <InputField
          isRequired
          label="Amenities"
          controller={{
            name: `amenities`,
            control,
          }}
          inputProps={{
            placeholder: "How many amenities ?",
            type: "number",
            isInt: true,
          }}
        />
      </div>
      <div className="mb-3">
        <InputField
          isRequired
          label="Transport options"
          controller={{
            name: `transportOptions`,
            control,
          }}
          inputProps={{
            placeholder: "How many transport options",
            type: "number",
            isInt: true,
          }}
        />
      </div>
      <div className="mb-3 mt-2">
        <span className="text-red-600/80 text-sm whitespace-pre-line">
          {`Once saved, all existing data related to Units, Amenities & Transport options will be deleted.
            Please backups important data before generating new data.`}
        </span>
      </div>
      <div className="flex gap-5 items-center mt-10">
        <Button
          disabled={formState.isSubmitting}
          type="button"
          onClick={() => setIsShow(false)}
          variant="text"
          className="w-full"
        >
          Cancel
        </Button>
        <Button
          isLoading={formState.isSubmitting}
          type="submit"
          disabled={!formState.isDirty}
          className="w-full"
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default NewCreatingForm;
