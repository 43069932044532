import React, { useEffect, useRef, useState } from "react";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";
import { getS3BEMediaUrl } from "../../../helper/media";
import {
  reqSetActiveGallery,
  reqSetIsShowVirtualModal,
} from "../../../reduxs/gallery/action";
import { useDispatch } from "react-redux";
import socket from "../../../helper/socket";
import Fancybox from "../../home-gallery/fancy-box";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import {
  IconCloseButton,
  LeftArrowIcon,
  RightArrowIcon,
} from "../../svgs/icons";
import { classNames } from "../../../helper/utils";

const PanaromaGallery = ({ data, isInfinity, isPresentation }) => {
  const dispatch = useDispatch();
  const pageRef = useRef(null);
  const [isShowFancyBox, setIsShowFancyBox] = useState(null);

  const onShowVirtualModal = (galleryId) => {
    dispatch(reqSetIsShowVirtualModal(true));
    dispatch(reqSetActiveGallery(galleryId));
  };

  const onCloseFancyBox = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_RENDER_GALLERY);
    }
    NativeFancybox.close();
  };

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          pageRef.current &&
            (pageRef.current.scrollTop =
              content.data.scrollTop * pageRef.current.scrollHeight);
        }
      });
    }
  }, [isPresentation]);

  const mediaData = data || [];

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop:
          event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };

  const onShowFancyBox = (index) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_RENDER_GALLERY, {
        index,
      });
    }
    const el = document.getElementById(`im-${index}`);
    if (el) {
      el.click();
      setIsShowFancyBox(`im-${index}`);
    }
  };

  const onNextImage = () => {
    NativeFancybox.getInstance().next();
  };

  const onPrevImage = () => {
    NativeFancybox.getInstance().prev();
  };

  return (
    <div ref={pageRef} className="overflow-y-auto h-full" onScroll={onScroll}>
      <div className="grid grid-cols-3 gap-[15px]">
        {mediaData?.map((item, index) => {
          return (
            <div
              key={`renders-${index}`}
              className={"renders-content-item h-[240px] w-full"}
            >
              <Fancybox
                key={`Fancybox-${index}`}
                options={{
                  infinite: true,
                  mainClass: "pure-fancy-box z-fancybox-9999",
                  showNavArrows: false,
                  on: {
                    "Carousel.change": (fancybox) => {
                      const el = fancybox.getSlide();
                      if (!isPresentation) {
                        socket.emitUIActionEvent(ACTION_NAME.JUMP_TO_FLOORPLAN, {
                          index: el.index,
                        });
                      }
                      setIsShowFancyBox(`im-${el.index}`);
                    },
                    destroy: () => {
                      if (!isPresentation) {
                        socket.emitUIActionEvent(ACTION_NAME.CLOSE_RENDER_GALLERY);
                      }
                      setIsShowFancyBox(null);
                    },
                  },
                }}
              >
                <div
                  className={`gallery-item h-full card-renders relative ${isInfinity ? "infinity" : ""}`}
                >
                  <img
                    id={`im-${index}`} // Update the ID to use globalIndex
                    onClick={() => onShowFancyBox(index)} // Pass globalIndex to onShowFancyBox
                    src={getS3BEMediaUrl(item.path)}
                    alt="normal-img"
                    data-fancybox="gallery"
                    className="object-cover h-full w-full"
                  />
                  <div className="absolute text-xs not-italic font-bold leading-[116%] tracking-[0.3px] uppercase text-white left-[15px] bottom-[15px]">
                    {item.name}
                  </div>
                </div>
              </Fancybox>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PanaromaGallery;
