/** @format */

import React, { useCallback, useState } from "react";
import { isEmpty } from "lodash";
import { Modal } from "../components/commons";
import closeIcon from "../../../assets/images/close-white.svg";
import { toast } from "react-toastify";
import mediaAPI from "../../../apis/api/media";
import playListApi from "../../../apis/api/playlist";
import * as yup from "yup";
import uploadIcon from "../../../assets/images/upload.svg";
import { MEDIA_TYPE } from "../utils";
import { nameMapping } from "./edit-modal";
import { useForm } from "react-hook-form";
import { playListTypeOptions } from "./left-panel/CreatePlayListForm";
import SelectField from "../configuration/components/selectField";

const filmValidationSchema = yup.object().shape({
  file: yup.mixed().required("File is a required field"),
  videoFile: yup.mixed().required("File is a required field"),
});

const AddMeidaContentModal = ({
  show,
  setShow,
  onCreateSuccess,
  mediaType: mediaTypeProp,
  gallery,
  handleSelectMedia,
  isFromMediaFolder,
}) => {
  const [files, setFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [mediaName, setMediaName] = useState();

  const [isDragging, setIsDragging] = useState(false);
  const [isVideoDragging, setIsVideoDragging] = useState(false);
  const [isShowLoading, setShowLoading] = useState(false);

  const { watch, control } = useForm({
    defaultValues: {
      mediaType: playListTypeOptions[0],
    },
  });

  const mediaType = mediaTypeProp || watch("mediaType")?.value;

  const handleFileSelect = (e, forVideo = false) => {
    const selectedFiles = Array.from(e.target.files);
    forVideo ? setVideoFiles(selectedFiles) : setFiles(selectedFiles);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDragOver = (e, forVideo) => {
    e.preventDefault();
    forVideo ? setIsVideoDragging(true) : setIsDragging(true);
  };

  const handleDragLeave = (e, forVideo) => {
    forVideo ? setIsVideoDragging(false) : setIsDragging(false);
  };

  const handleDrop = (e, forVideo = false) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    if (forVideo && droppedFiles[0].type.startsWith("video")) {
      setVideoFiles(droppedFiles);
      return setErrors({ ...errors, videoFile: "" });
    } else if (forVideo && !droppedFiles[0].type.startsWith("video")) {
      return setErrors({ ...errors, videoFile: "Invalid file type" });
    } else if (!droppedFiles[0].type.startsWith("image")) {
      return setErrors({ ...errors, file: "Invalid file type" });
    } else {
      setErrors({ ...errors, file: "" });
      return setFiles(droppedFiles);
    }
  };

  const renderImagePreview = () => {
    if (files.length) {
      return URL.createObjectURL(files[0]);
    }
  };
  const renderVideoPreview = useCallback(() => {
    if (videoFiles.length) {
      return URL.createObjectURL(videoFiles[0]);
    }
  }, [videoFiles[0]]);

  const addImgMediaPlayList = async () => {
    setShowLoading(true);
    try {
      const formData = new FormData();

      formData.append("file", files[0]);

      const uploaded = await mediaAPI.uploadMedia(formData);

      if (uploaded?.data) {
        let params = {
          isActive: true,
          path: uploaded?.data?.path,
          thumbnailPath: uploaded?.data?.thumbnailPath,
          type: mediaType,
          name: mediaName,
          fileName: files[0]?.name,
        };

        if (!isFromMediaFolder) {
          params.playlistId = gallery.id;
        }
        const res = await playListApi.createMediaPlaylist(params);
        if (res && res?.data) {
          toast.success("Add new content successfully!");
          handleClose();
          onCreateSuccess && onCreateSuccess(res?.data);
        }
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
    } finally {
      setShowLoading(false);
    }
  };

  const addVideoPlayList = async () => {
    setShowLoading(true);
    try {
      const data = {
        file: files[0],
        videoFile: videoFiles[0],
      };
      setErrors({});
      const result = await filmValidationSchema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append("file", result.videoFile);

      const formDataThumbnail = new FormData();
      formDataThumbnail.append("file", result.file);

      const uploaded = await mediaAPI.uploadMedia(formData);
      const uploadedThumbnail = await mediaAPI.uploadMedia(formDataThumbnail);

      if (uploaded?.data && uploadedThumbnail?.data) {
        let params = {
          isActive: true,
          path: uploaded?.data?.path,
          type: mediaType,
          name: mediaName,
          fileName: files[0]?.name,
          thumbnailPath: uploadedThumbnail?.data?.path,
        };
        if (!isFromMediaFolder) {
          params.playlistId = gallery.id;
        }
        const res = await playListApi.createMediaPlaylist(params);
        if (res && res?.data) {
          toast.success("Add new content successfully!");
          handleClose();
          onCreateSuccess && onCreateSuccess(res?.data);
        }
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
    } finally {
      setShowLoading(false);
    }
  };

  const addConentMediaPlayList = async () => {
    if (mediaType !== MEDIA_TYPE.VIDEO) {
      addImgMediaPlayList();
    } else {
      addVideoPlayList();
    }
  };

  const onSave = () => {
    if (validateForm()) {
      return;
    }
    addConentMediaPlayList();
  };

  const validateForm = () => {
    let hasErrors = Object.values(errors).find((error) => error);
    if (!mediaName) {
      setErrors({ ...errors, mediaName: "Media name is required" });
      hasErrors = true;
    }

    return !!hasErrors;
  };

  const onChangeMediaName = (value) => {
    if (!value) {
      setErrors({ ...errors, mediaName: "Media name is required" });
    } else {
      setErrors({ ...errors, mediaName: undefined });
    }

    setMediaName(value);
  };

  const onChangeType = useCallback(() => {
    setFiles([]);
    setVideoFiles([]);
    setErrors({});
  }, []);

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      isLoading={isShowLoading}
      wrapperClassName="wrap-update-content-modal"
      panelClassName="w-[630px] p-0 rounded-none"
      zIndex="z-[9998]"
      maskClassName="opacity-90"
      show={show}
      setShow={handleClose}
      content={
        <div className="p-[40px] max-h-[90vh] ">
          <div className="flex flex-row gap-4 items-center">
            {!isFromMediaFolder && (
              <div className="flex flex-row gap-4 items-center">
                <p className="text-[12px] text-gray-700">or</p>
                <div
                  onClick={handleSelectMedia}
                  className="text-black text-[16px] font-medium font-poppins border-dashed border-2 border-gray-400 p-2 cursor-pointer"
                >
                  Select File
                </div>
              </div>
            )}
          </div>
          <div className="text-white relative flex flex-col gap-10 max-h-[calc(100vh_-_290px)] p-0 overflow-y-auto">
            <div>
              <div>
                <div className="flex justify-between flex-wrap">
                  {mediaTypeProp && (
                    <h2 className="font-400 mb-4 text-xl font-bold text-black">
                      {nameMapping[mediaType]}
                    </h2>
                  )}
                  {!mediaTypeProp && (
                    <SelectField
                      wrapperOptionsClassName="p-[10px] rounded-[8px] border-[#000]"
                      labelClassName="text-[14px] w-full text-[#5d5d5d] mb-[10px] font-[500]"
                      controller={{ control, name: "mediaType" }}
                      required
                      label="Media Type"
                      options={playListTypeOptions}
                      callbackOnChange={onChangeType}
                      placeholder="Please select type of playlist"
                    />
                  )}
                  <button onClick={handleClose} className="underline">
                    Close
                  </button>
                </div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Media name *
                </label>
                <div className="mt-2">
                  <input
                    onChange={(e) => onChangeMediaName(e.target.value)}
                    value={mediaName}
                    id="mediaName"
                    name="mediaName"
                    placeholder="Media name"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <span className="text-red-500">{errors?.mediaName}</span>
                </div>
              </div>
              {mediaType === MEDIA_TYPE?.VIDEO && (
                <>
                  <div
                    className={`relative min-h-[330px] flex flex-col justify-center mt-4 mb-4 border-dashed border-2 border-gray-400 p-4 rounded-md ${
                      isVideoDragging ? "bg-gray-200" : "bg-white"
                    }`}
                    onDragOver={(e) => handleDragOver(e, true)}
                    onDragLeave={(e) => handleDragLeave(e, true)}
                    onDrop={(e) => handleDrop(e, true)}
                  >
                    {!isEmpty(videoFiles) && (
                      <video
                        key={
                          videoFiles.length
                            ? URL.createObjectURL(videoFiles[0])
                            : "default"
                        }
                        className="absolute top-0 left-0 w-full img-fluid cursor-pointer h-[325px] object-cover"
                      >
                        <source src={renderVideoPreview()} type="video/mp4" />
                      </video>
                    )}
                    <input
                      type="file"
                      multiple
                      className="hidden"
                      id="video-upload"
                      onChange={(e) => handleFileSelect(e, true)}
                      accept={"video/*"}
                    />
                    <label htmlFor="video-upload" className="cursor-pointer">
                      <div className="text-center flex flex-col items-center">
                        <p className="text-gray-600">
                          Drag & drop your video here or click to upload
                        </p>
                        {!isEmpty(videoFiles) && (
                          <img
                            src={uploadIcon}
                            alt="Upload"
                            className="w-20 h-20 relative"
                          />
                        )}
                        {videoFiles.length > 0 && (
                          <ul className="mt-4">
                            {videoFiles.map((file, index) => (
                              <li key={index} className="text-sm text-gray-800">
                                {file.name}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </label>
                  </div>
                  {videoFiles.length > 0 && (
                    <ul>
                      {videoFiles.map((file, index) => (
                        <li
                          key={index}
                          className="text-sm text-gray-800 text-center"
                        >
                          {file.name}
                        </li>
                      ))}
                    </ul>
                  )}
                  <span className="text-[#ae6537] text-sm not-italic font-normal leading-[150%]">
                    {errors?.videoFile}
                  </span>
                  <h3 className="text-black text-[20px] font-[600] uppercase font-poppins">
                    THUMBNAIL
                  </h3>
                </>
              )}
              <div
                className={`relative min-h-[330px] flex flex-col justify-center mt-4 mb-4 border-dashed border-2 border-gray-400 p-4 rounded-md ${
                  isDragging ? "bg-gray-200" : "bg-white"
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                {!isEmpty(files) && (
                  <img
                    className="absolute top-0 left-0 w-full img-fluid cursor-pointer h-[325px] object-cover"
                    src={renderImagePreview()}
                    alt=""
                  />
                )}
                <input
                  type="file"
                  multiple
                  className="hidden"
                  id="file-upload"
                  onChange={handleFileSelect}
                  accept={"image/*"}
                />
                <label htmlFor="file-upload" className="cursor-pointer">
                  <div className="text-center flex flex-col items-center">
                    <p className="text-gray-600">
                      Drag & drop your files here or click to upload
                    </p>
                    {!isEmpty(files) && (
                      <img
                        src={uploadIcon}
                        alt="Upload"
                        className="w-20 h-20 relative"
                      />
                    )}
                    {files.length > 0 && (
                      <ul className="mt-4">
                        {files.map((file, index) => (
                          <li key={index} className="text-sm text-gray-800">
                            {file.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </label>
              </div>
              <span className="text-[#ae6537] text-sm not-italic font-normal leading-[150%]">
                {errors?.file}
              </span>
            </div>
            <div
              className="font-poppins cursor-pointer text-[14px] font-[700] text-[#000] mt-[20px] px-[18px] py-[7px] uppercase border-solid border-[1px] border-[#000] w-full text-center"
              onClick={onSave}
            >
              SAVE
            </div>
          </div>
        </div>
      }
    />
  );
};

export default AddMeidaContentModal;
