/** @format */

import PATH from "./path";
import Index from "../pages/Index";
import NotFoundPage from "../pages/404";
import ForgotPassword from "../pages/auth/forgot-password";
import ResetPassword from "../pages/auth/reset-password";
import Session from "../components/session";
import HoldingPage from "../pages/holding";
import CMS from "../pages/cms";
import Panorama from "../pages/360-pano";
import Presentation from "../pages/presentation";
import CMSFrequentlyAQS from "../pages/cms/faq";
import CMSUnits from "../pages/cms/units";
import CMSCustomers from "../pages/cms/customers";
import CMSAmenities from "../pages/cms/amenities";
import CMSAnalytics from "../pages/cms/analytics";
import CMSProfile from "../pages/cms/profile";
import CMSMedia from "../pages/cms/media";
import CMSAssets from "../pages/cms/assets/cms-assets";
import CMSConfiguration from "../pages/cms/configuration";
import { Navigate } from "react-router-dom";
import { CMSGallery } from "../pages/cms/gallery";
import { CMSPlaylist } from "../pages/cms/playlists";

export const routeOptions = [
  {
    path: PATH.ROOT,
    name: "Home",
    component: Index,
    roles: ["admin", "investor", "consultant"],
    exact: true,
  },
  {
    path: PATH.FORGOT_PASSWORD,
    name: "Forgot password",
    component: ForgotPassword,
    roles: [],
  },
  {
    path: PATH.RESET_PASSWORD,
    name: "Reset password",
    component: ResetPassword,
    roles: [],
  },
  {
    path: PATH.NOTFOUND_PAGE,
    name: "Not found",
    component: NotFoundPage,
    roles: [],
  },
  {
    path: PATH.CUSTOMER_SESSION_PAGE,
    name: "customer session",
    component: Session,
    roles: [],
  },
  {
    path: PATH.HOLDING_PAGE,
    name: "holding",
    component: HoldingPage,
    roles: [],
  },
  {
    path: PATH.CMS_PAGE,
    name: "cms",
    component: CMS,
    roles: ["admin"],
    subRoutes: [
      { path: "", element: () => <Navigate to="units" replace /> },
      { path: "units", element: CMSUnits },
      { path: "customers", element: CMSCustomers },
      { path: "amenities", element: CMSAmenities },
      { path: "analytics", element: CMSAnalytics },
      { path: "faqs", element: CMSFrequentlyAQS },
      { path: "profile", element: CMSProfile },
      { path: "content", element: CMSGallery },
      { path: "gallery", element: CMSGallery },
      { path: "playlists", element: CMSPlaylist },
      { path: "configuration", element: CMSConfiguration },
      { path: "assets", element: CMSAssets },
      { path: "assets-v2", element: CMSMedia },
    ],
  },
  {
    path: PATH.PANORAMA_PAGE,
    name: "panorama",
    component: Panorama,
    roles: ["admin"],
  },
  {
    path: PATH.PRESENTATION_PAGE,
    name: "Presentation Page",
    component: Presentation,
    roles: ["admin"],
  },
];

export default routeOptions;
