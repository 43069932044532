import React, { Fragment } from "react";
import { cn } from "../../../../helper/utils";

import Empty from "../../configuration/components/Table/EmptyTable";

// export type ListProps<T> = {
//     className?: string;
//     items?: T[];
//     renderItem?: (item: T) => React.ReactNode;
//     loading?: boolean;
//     empty?: React.ReactNode;
//     lengthLoading?: number;
//     loadingComponent?: ((index: number) => React.ReactNode) | React.ReactNode;
// };

const Placeholder = () => {
  return (
    <div className="min-h-[250px] animate-pulse bg-grey1 my-2 rounded-lg" />
  );
};

function List(props) {
  const {
    items = [],
    className,
    renderItem,
    loading,
    lengthLoading = 3,
    loadingComponent = null,
  } = props;

  const renderLoadingComponent = (index) => {
    if (typeof loadingComponent === "function") return loadingComponent(index);

    return loadingComponent || <Placeholder key={index} />;
  };

  return (
    <React.Fragment>
      <div className={cn("grid", className)}>
        {loading &&
          Array.from({ length: lengthLoading }).map((_, index) => {
            return renderLoadingComponent(index);
          })}

        {!loading && <Fragment>{renderItem?.(items)}</Fragment>
          }
      </div>

      {items.length === 0 &&
        !loading &&
        (props?.empty || <Empty className={props?.emptyClassName} />)}
    </React.Fragment>
  );
}
export default List;
