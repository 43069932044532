/** @format */

import React, { useState, useEffect, useRef, useCallback } from "react";
import FloorPlans from "./components/floor-plans/floor-plans";
import galleryApi from "../../apis/api/gallery";
import { useDispatch } from "react-redux";
import { reqGetGalleryList } from "../../reduxs/gallery/action";
import { FLOOR_TYPE, LEVEL_TYPE } from "../../constants/master-data";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import { Amenities } from "./components/amenities";
import { Exteriors } from "./components/exteriors";
import { Interiors } from "./components/interiors";
import { PAGES_ENUM } from "../../constants/modules";
import { MEDIA_TYPE } from "../../pages/cms/utils";

const GalleryLandingA = ({ isPresentation, pages }) => {
  const [subTabActive, setSubTabActive] = useState(0);
  const [floor, setFloor] = useState([]);
  const [floorDetail, setFloorDetail] = useState({});
  const data = pages?.find((i) => i.name === PAGES_ENUM.GALLERY_TYPE_A);
  const features = data?.modules?.[0]?.features?.[0];

  useEffect(() => {
    if (features.components.length > 0) {
      const idxActive = features.components?.findIndex((i) => i.props.visible);
      if (idxActive !== -1) {
        setSubTabActive(idxActive);
      }
    }
  }, [features.components]);

  const dispatch = useDispatch();

  const pageRef = useRef(null);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CHANGE_ACTIVE_SUB_GALLERY_TAB) {
      return onClickSubTab(content.data.idx);
    }
    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollTop * pageRef.current.scrollHeight;
      }
    }
  };
  useEffect(() => {
    dispatch(reqGetGalleryList());

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const getListGallery = async () => {
    if (features?.components?.[subTabActive].name.includes("floorplans")) {
      await handleGetListLevel(LEVEL_TYPE.BHT_LEVEL);
      await handleGetGalleryDetail(FLOOR_TYPE.BAKU_FLOOR);
    }
  };

  useEffect(() => {
    getListGallery();
  }, [subTabActive]);

  const handleGetListLevel = async (type) => {
    try {
      const data = {
        "type[equal]": type,
      };
      const res = await galleryApi.getListGalleryFloor(data);
      if (res) {
        setFloor(res.data);
      }
    } catch (e) {
      console.log("error get floor");
    }
  };

  const handleGetGalleryDetail = async (type) => {
    try {
      const data = {
        "type[equal]": type,
      };
      const floorDetailRes = await galleryApi.findOneGalleryDetail(data);
      if (floorDetailRes) {
        setFloorDetail(floorDetailRes.data);
      }
    } catch (e) {
      console.log("error get floor");
    }
  };

  const onClickSubTab = (idx) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CHANGE_ACTIVE_SUB_GALLERY_TAB, {
        idx,
      });
    }
    setSubTabActive(idx);
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop:
          event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };

  const renderTab = () => {
    return (
      <div className="flex absolute w-full flex-row justify-around z-[99] mt-[49px]">
        {features?.components?.map((item, idx) => {
          if (item.props.visible) {
            return (
              <div
                onClick={() => onClickSubTab(idx)}
                key={`subTab-${idx}`}
                className={`flex flex-col items-center justify-center cursor-pointer h-[51px] bg-white w-full z-[99] border-l-[rgba(36,36,34,0.15)] border-t-[unset] border-b-[rgba(36,36,34,0.15)] border-l border-solid border-b ${
                  subTabActive === idx ? "active" : ""
                }`}
              >
                <div className="text-[#242422] text-[12px] not-italic font-normal leading-[normal] tracking-[1.2px] uppercase">
                  {item?.props?.label}
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  const renderContent = useCallback(() => {
    if (features?.components.length > 0) {
      // if (features?.components?.[subTabActive].name.includes("amenities")) {
      //   return (
      //     <Amenities
      //       isPresentation={isPresentation}
      //       item={features?.components?.[subTabActive]}
      //     />
      //   );
      // }
      if (
        features?.components?.[subTabActive]?.props?.type ===
        MEDIA_TYPE?.FLOORPLANS
      ) {
        return <FloorPlans isPresentation={isPresentation} data={floor} />;
      }
      // if (features?.components?.[subTabActive].name.includes("exteriors")) {
      //   return (
      //     <Exteriors
      //       isPresentation={isPresentation}
      //       category="exteriors"
      //       item={features?.components?.[subTabActive]}
      //     />
      //   );
      // }
      // if (features?.components?.[subTabActive].name.includes("interiors")) {
      //   return (
      //     <Interiors
      //       isPresentation={isPresentation}
      //       item={features?.components?.[subTabActive]}
      //     />
      //   );
      // }
      // if (features?.components?.[subTabActive].name.includes("info")) {
      //   return (
      //     <Exteriors
      //       isPresentation={isPresentation}
      //       category="info"
      //       item={features?.components?.[subTabActive]}
      //     />
      //   );
      // }
      return (
        <Amenities
          isPresentation={isPresentation}
          item={features?.components?.[subTabActive]}
        />
      );
    }
  }, [subTabActive]);

  return (
    <div
      className={`absolute bg-[#fcfcfc] overflow-x-hidden overflow-y-scroll w-screen h-full mt-0 mb-[60px] top-0 ${
        isPresentation && "presentation"
      }`}
    >
      {renderTab()}
      <div
        className="h-[100svh] overflow-y-scroll pt-[100px] pb-[49px]"
        ref={pageRef}
        onScroll={onScroll}
      >
        {renderContent()}
      </div>
    </div>
  );
};
export default GalleryLandingA;
