import React, { useState, useEffect, useMemo, useCallback } from "react";

import {
  ACTION_NAME,
  GALLERY_TYPE,
  WEBSOCKET_CHANNEL,
} from "../../constants/options";
import Renders from "./components/renders";
import Films from "./components/films";
import PanaromaGallery from "./components/panaroma-gallery";
import FloorPlans from "./components/floor-plans/floor-plans";
import { useDispatch, useSelector } from "react-redux";
import { reqGetGallery } from "../../reduxs/gallery/action";
import socket from "../../helper/socket";
import {
  fakeMediaImg,
  fakeMediaFilm,
  fakeMediaImg360,
  fakeMediaFloor,
} from "./mock/data";
import Tabs from "./components/tabs";
import { MEDIA_TYPE } from "../../pages/cms/utils";
import { List } from "../../pages/cms/components/commons";
import SkeletonLoading from "../../pages/cms/components/commons/SkeletonLoading";
import { cn, handleSortOrder } from "../../helper/utils";

const GalleryLanding = (props) => {
  const { isPresentation, pages } = props;

  const dispatch = useDispatch();
  const { gallery, galleryLoading } = useSelector((state) => state.gallery);
  const [isFloorplans, setIsFloorlplans] = useState(false);
  const galleryTypeB = useMemo(() => {
    const item = pages?.find((v) => v?.name === "gallery_page_type_b");

    return {
      ...item,
      visiblePage: item?.props.visible,
      data: item?.modules?.map((v) => ({
        ...v,
        tabs: v?.features[0]?.components || [],
      })),
    };
  }, [pages]);
  const isShow = galleryTypeB && galleryTypeB?.visiblePage;

  const [tabActive, setTabActive] = useState(0);
  const [subTabActive, setSubTabActive] = useState(0);
  const {componentActive, galleryType} = useMemo(() => {
    const componentActive = galleryTypeB?.data?.[tabActive]?.features?.[0]?.components || [];
    const galleryType = componentActive?.[subTabActive]?.props?.type;
    return { componentActive, galleryType }
  }, [galleryTypeB, tabActive, subTabActive])

  useEffect(() => console.log(galleryType), [galleryType])
    useEffect(() => {
      setIsFloorlplans(
        componentActive?.[subTabActive]?.props?.type === MEDIA_TYPE?.FLOORPLANS
      );
    }, [componentActive, subTabActive]);

  const getGallery = (idGallery) => dispatch(reqGetGallery(idGallery));

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CHANGE_ACTIVE_GALLERY_TAB) {
          return onClickTab(content.data.idx);
        }
        if (content.action === ACTION_NAME.CHANGE_ACTIVE_SUB_GALLERY_TAB) {
          return onClickSubTab(content.data.idx);
        }
      });
    }
  }, []);

  useEffect(() => {
    const idGallery = componentActive?.[subTabActive]?.id;

    idGallery && getGallery(idGallery);
  }, [subTabActive, componentActive, subTabActive]);

  const onClickTab = (idx) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CHANGE_ACTIVE_GALLERY_TAB, {
        idx,
      });
    }
    setTabActive(idx);
    setSubTabActive(0);
  };

  const onClickSubTab = (idx) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CHANGE_ACTIVE_SUB_GALLERY_TAB, {
        idx,
      });
    }
    setSubTabActive(idx);
    setIsFloorlplans(
      componentActive?.[idx]?.props?.type === MEDIA_TYPE?.FLOORPLANS
    );
  };

  const renderContentGallery = (items) => {
    if (
      componentActive?.length > 0 &&
      componentActive?.[subTabActive].props.visible
    ) {
      const content = {
        [MEDIA_TYPE.IMAGE]: (
          <Renders data={items} isPresentation={isPresentation} />
        ),
        [MEDIA_TYPE.VIDEO]: (
          <Films data={items} isPresentation={isPresentation} />
        ),
        [MEDIA_TYPE.PARONAMIC]: (
          <PanaromaGallery
            data={items}
            isPresentation={isPresentation}
          />
        ),
        [MEDIA_TYPE['360IMAGE']]: (
          <PanaromaGallery
            data={items}
            isPresentation={isPresentation}
          />
        ),
      };

      return content[gallery?.playlist?.type] || null;
    }
  };

  const sortedMedias = useMemo(() => handleSortOrder(
    gallery?.playlist?.medias.filter(media => galleryType ? media?.type === galleryType : true) || [],
    gallery?.playlistOverride || []
  ), [gallery, galleryType]);
  return (
    <div className="absolute bg-[#0c2032] w-screen h-[100vh] overflow-y-scroll overflow-x-hidden flex flex-row  mt-[49px] mb-[60px] pt-[123px] px-[90px] py-20 top-0">
      {isShow && (
        <div className="h-full w-full">
          <Tabs
            tabActive={tabActive}
            subTabActive={subTabActive}
            onClickTab={onClickTab}
            onClickSubTab={onClickSubTab}
            galleries={galleryTypeB?.data || []}
          />
          <div className="h-full">
            {!isFloorplans && (
              <List
                loadingComponent={(index) => (
                  <SkeletonLoading key={`skeleton-${index}`} />
                )}
                lengthLoading={isFloorplans ? 1 : 10}
                loading={galleryLoading}
                items={sortedMedias}
                renderItem={renderContentGallery}
                className={cn("grid grid-cols-1 gap-5 pt-[100px] pb-[200px]", {
                  "pt-[100px] pb-0": !gallery?.playlist?.medias?.length,
                  "grid-cols-1": isFloorplans,
                })}
                emptyClassName="text-white"
              />
            )}
            {isFloorplans && (
              <FloorPlans
                isPresentation={isPresentation}
                data={fakeMediaFloor}
                tabActive={tabActive}
                subTabActive={subTabActive}
              />
            )}
          </div>
        </div>
      )}
      {!isShow && (
        <div className="w-full flex justify-center items-center h-full">
          <p className="text-2xl text-white font-medium">
            No gallery found. Please create one.
          </p>
        </div>
      )}
    </div>
  );
};
export default GalleryLanding;
