import React from "react";

import { getS3BEMediaUrl } from "../../../helper/media";
import { cn } from "../../../helper/utils";
import icVideo from "../../../assets/images/cms/icVideo.png";

const VideoPlayer = ({ videoUrl, videoClassName }) => {
  const [videoError, setVideoError] = React.useState(false);

  const handleVideoError = () => {
    setVideoError(true);
  };

  return (
    <>
      <video
        className={cn("w-full h-full", videoClassName)}
        controls
        autoPlay
        onError={handleVideoError}
      >
        <source src={getS3BEMediaUrl(videoUrl)} />
        <p>Your browser does not support the video tag.</p>
      </video>
      {videoError && <img src={icVideo} alt="Video not display" />}
    </>
  );
};

export default VideoPlayer;
