import React, { useEffect, useState } from "react";
import themeApi from "../../../../apis/api/theme";
import { toast } from "react-toastify";

const EditableItem = ({ item, themeId, onSuccess, disabled = false }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState();

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => setValue(item.value), [item.value])

  const onEditVariable = async () => {
    try {
        const res = await themeApi.updateVariables(themeId, item._id, { value });
        toast.success('Update variable successfully')
        if (res && res.data) {
            onSuccess && onSuccess()
            setIsEditing(false)
        }
    } catch (error) {
        toast.error('Update variable failed')
    }
  }


  const handleBlur = () => {
    setValue(item.value);
    setIsEditing(false);
  };

  const handleMouseDown = (e) => {
    e.preventDefault(); 
  };

  return (
    <div className="flex justify-between border-t border-b border-gray-300">
      <div className="flex items-center w-[50%] p-4 border-r border-gray-300">
        <span>{item.name}</span>
      </div>
      <div className="flex items-center w-[50%] pl-4">
        <input
          type="color"
          value={value}
          onChange={handleInputChange}
          className="w-8 h-8 cursor-pointer disabled:cursor-not-allowed"
          disabled={disabled}
        />
        <div className="ml-2">
          <span>{value}</span>
        </div>
        {value !== item.value && <>
          <button onMouseDown={handleMouseDown} type="button" onClick={onEditVariable} className="ml-4 text-gray-500 text-sm">Save</button>
          <button onMouseDown={handleMouseDown} type="button" onClick={handleBlur} className="ml-4 text-gray-500 text-sm">Cancel</button>
        </>}
      </div>
    </div>
  );
};

export default EditableItem;
