import React, { useEffect, useState } from "react";
import { getS3FEMediaUrl, getS3BEMediaUrl } from "../../../helper/media";
import socket from "../../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";
import PlayVideo from "./play-video";
import defaultVideo from "../../../assets/images/cms/icVideo.png";

const Films = ({ data, isInfinity, isPresentation }) => {
  const [isShowVideo, setShowVideo] = useState(false);
  const [urlVideo, setUrlVideo] = useState("");
  const [urlThumbVideo, setUrlThumbVideo] = useState("");
  const [loadedImages, setLoadedImages] = useState({}); 
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action == ACTION_NAME.PLAY_VIDEO) {
          return handlePlayVideo(content.data.item);
        }
        if (content.action == ACTION_NAME.STOP_VIDEO) {
          return onStopVideo();
        }
      });
    }
  }, [isPresentation]);

  const handlePlayVideo = (item) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PLAY_VIDEO, {
        item: item,
      });

      if (item?.message) {
        const message = item.message;
        message.FG.MSG_ID = Date.now();
        socket.shareMedia(message);
      }
    }

    setShowVideo(true);
    setUrlVideo(item.path);
    setUrlThumbVideo(item.thumbnailPath);
  };

  const onStopVideo = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.STOP_VIDEO);
    }
    setShowVideo(false);
    setUrlVideo("");
  };

  const handleImageLoadError = (idx) => {
    setLoadedImages((prev) => ({
      ...prev,
      [idx]: defaultVideo, // Use the default image if loading fails
    }));
  };

  const handleImageLoadSuccess = (idx, url) => {
    setLoadedImages((prev) => ({
      ...prev,
      [idx]: url, // Successfully loaded image
    }));
  };

  return (
    <div className="overflow-y-auto h-full">
      <div className="grid gap-x-[15px] gap-y-[15px] grid-cols-[repeat(2,1fr)]">
        {data?.map((i, idx) => {
          const thumbnailUrl = getS3BEMediaUrl(i.thumbnailPath);

          return (
            <div
              key={`films-${idx}`}
              data-fancybox="gallery"
              className={`h-[286px] bg-white flex flex-col bg-cover bg-no-repeat cursor-pointer ${isInfinity && "infinity"}`}
              style={{
                backgroundImage: `url(${loadedImages[idx] || defaultVideo})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
              onClick={() => handlePlayVideo(i)}
            >
              {/* Hidden image loader to detect load success/failure */}
              <img
                src={thumbnailUrl}
                alt="thumbnail"
                style={{ display: "none" }} // Hide the image, only used for loading
                onLoad={() => handleImageLoadSuccess(idx, thumbnailUrl)}
                onError={() => handleImageLoadError(idx)}
              />
            </div>
          );
        })}
      </div>
      {isShowVideo && (
        <PlayVideo
          isPresentation={isPresentation}
          urlVideo={urlVideo}
          onStopVideo={onStopVideo}
          urlBg={urlThumbVideo}
          setShowVideo={setShowVideo}
        />
      )}
    </div>
  );
};

export default Films;
