import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../../../components/commons";
import EditIcon from "../../../components/svgs/EditIcon";
import { EditModelMediaModal } from "../modals/edit-model-modal";
import _3dSettingsApi from '../../../../../../apis/api/_3dSettings';
import { reqGetModelsMediaList } from "../../../../../../reduxs/media/action";

export const CellEditModel = ({ rowComponent }) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        variant="text"
        className="text-blue-500 border-blue-500"
        onClick={() => setShow(!show)}
      >
        <EditIcon />
      </Button>

      {
        show &&
        <EditModelMediaModal
          content={rowComponent}
          show={show}
          setShow={setShow}
          onSaveSuccess={() => { dispatch(reqGetModelsMediaList()) }}
        />
      }
    </>
  );
};
